import { Link } from "react-router-dom";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH, faCaretDown } from "@fortawesome/free-solid-svg-icons";

import { sidebarSubcategory } from "./subcategory";

function SidebarWeb(props) {
  const [collapse, setCollapse] = useState(false);
  console.log(props.subcategories);
  return (
    <button className="menu">
      <div className="menu__title" onClick={() => setCollapse(!collapse)}>
        <span className="font__500">{props.categories}</span>
        <FontAwesomeIcon
          style={{
            marginTop: "10px",
            marginLeft: "10px",
            verticalAlign: "-0.3em",
          }}
          icon={faCaretDown}
          size="2x"
        />
      </div>
      <ul className={`sidebar_dropdown`}>
        {props.subcategories.map((object, i) => (
          <li className={`submenu submenu__animation-${i}`} key={i}>
            <Link to={`/products/${object.link}`}>
              <p
                className="subcategory"
                onClick={() => props.onChangeSubCategory(object.sub)}
              >
                {object.sub}
              </p>
            </Link>
          </li>
        ))}
        <div className="sidebar__divider"></div>
      </ul>
    </button>
  );
}

function SidebarMobile(props) {
  let submenuDropdown = props.submenuDropdown || false;
  const [collapse, setCollapse] = useState(submenuDropdown);
  const [click, setOnClick] = useState({});

  return (
    <button className="menu">
      <div className="menu__title" onClick={() => setCollapse(!collapse)}>
        <span className="font__500">{props.categories}</span>
        <FontAwesomeIcon
          style={{
            marginTop: "10px",
            marginLeft: "10px",
            verticalAlign: "-0.3em",
          }}
          icon={faCaretDown}
          size="2x"
        />
      </div>
      <ul className={`sidebar_dropdown ${collapse ? "cat-open" : "cat-close"}`}>
        {props.subcategories.map((object, i) => (
          <li className={`submenu submenu__animation-${i} `} key={i}>
            <Link to={`/products/${object.link}`}>
              <p
                className={` ${click ? "active" : ""}`}
                onClick={() => props.onChangeSubCategory(object.sub)}
              >
                {object.sub}
              </p>
            </Link>
          </li>
        ))}
      </ul>
    </button>
  );
}

function subCatToCat(sidebarSubcategory) {
  let sidebarCatGroups = {};
  sidebarSubcategory.forEach((element) => {
    const { catId, categories, link, subcategories } = element;

    if (Object.keys(sidebarCatGroups).includes(categories)) {
      sidebarCatGroups[categories].push({
        catId: catId,
        sub: subcategories,
        link: link,
      });
    } else {
      sidebarCatGroups[categories] = [
        { catId: catId, sub: subcategories, link: link },
      ];
    }
  });
  return sidebarCatGroups;
}

export default function Sidebar(props) {
  const media = props.media;
  let menuDropdown = props.menuDropdown || false;
  let submenuDropdown = props.submenuDropdown || false;

  const [collapse, setCollapse] = useState(menuDropdown);
  const sidebarCatGroups = subCatToCat(sidebarSubcategory);
  const sidebarCollapseClassname = () => {
    const defaultClassName = "sidebar__content flex flex-dir-col";
    if (media === "mobile") {
      if (collapse) {
        return defaultClassName + " cat-open";
      }
      return defaultClassName + " cat-close";
    }
    return defaultClassName;
  };

  return (
    <div className="sidebar">
      <div className="sidebar__header" onClick={() => setCollapse(!collapse)}>
        <FontAwesomeIcon
          style={{
            marginTop: "10px",
            marginRight: "5px",
            fontSize: "1.7em",
          }}
          icon={faSlidersH}
        />
        <h2 style={{ display: "inline" }}> Categories </h2>
      </div>
      {/* <div className="sidebar__content flex flex-dir-col"> */}
      <div className={sidebarCollapseClassname()}>
        {Object.keys(sidebarCatGroups).map((object, i) => {
          return media === "web" ? (
            <SidebarWeb
              categories={object}
              subcategories={sidebarCatGroups[object]}
              onChangeSubCategory={props.onChangeSubCategory}
              key={i}
            />
          ) : (
            <SidebarMobile
              categories={object}
              submenuDropdown={submenuDropdown}
              subcategories={sidebarCatGroups[object]}
              onChangeSubCategory={props.onChangeSubCategory}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
}
