import Navbar from "../header/navbar";
import Footer from "../footer/footer";
import { productData } from "../product/productData.js";

import { sidebarSubcategory } from "../sidebar/subcategory";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import WhatsappContact from "../utils/whatsapp";
import React, { useState, useEffect } from "react";

function filterSubcategory(subcategory) {
  return sidebarSubcategory.filter(
    (data) => data.subcategories === subcategory
  );
}

function HalalProductCards(props) {
  const productDataHalal = props.productData.filter(function (val) {
    return val.halal === "Halal";
  });

  const HalalProductCard = (props) => {
    const productCategory = filterSubcategory(props.productSubCat);
    const link = productCategory[0].link;

    return (
      <Link to={`/products/${link}`}>
        <div className="halal__cards">
          <div className="product-text">
            <p className="product-id">
              {props.productId} - {props.name}
            </p>
            <p className="product-cat">
              {props.productCat}{" "}
              {/* <span className="product-subcat"> - {props.productSubCat}</span> */}
            </p>
          </div>
          <div className="product-arrow">
            <FontAwesomeIcon
              icon={faArrowRight}
              size="1x"
              className="icon"
              style={{ marginLeft: "5px" }}
            />
          </div>
        </div>
      </Link>
    );
  };

  const halalProductList = productDataHalal.map((val, idx) => (
    <HalalProductCard
      key={idx}
      name={val.name}
      productId={val.productId}
      productCat={val.categories}
      productSubCat={val.subcategories}
      buyLink={val.buyLink}
    />
  ));

  return <div className="product-contents">{halalProductList}</div>;
}

function HalalPage(props) {
  const mobileWidth = 601;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < mobileWidth;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <div className="halal">
      <Navbar currentPage="halal" />
      <div className="row hero-factory hero-margin">
        <img
          src={`${
            isMobile
              ? "img/about/halal_hero_small.png"
              : "img/about/halal_hero.png"
          }`}
          alt="Halal Research "
          style={{ width: "100%" }}
        />
      </div>
      <div className="container-color" style={{ backgroundColor: "#f3f3f3" }}>
        <div className="container">
          <div className="value__title halal__title">
            <h1> Halal Certificate </h1>
          </div>
          <div className="halal__contents">
            <div className="left">
              <img src="img/about/halal_status.png" alt="Halal Certificate" />
              <p>
                <span style={{ fontSize: "3rem", fontWeight: "800" }}>B</span>
                ased on The Indonesian Council of Ulama{" "}
                <strong>(LPPOM-MUI) </strong>
                stated that <strong>PT.Sadya Balawan</strong> has already passed
                Halal Assurance System Status with category Excellent.
              </p>
            </div>
            <div className="right">
              <p>
                To meet the needs of our customers who produce food or produce
                packaging used for food, we carry out halal certification for
                our enzyme products.
              </p>
              <p>
                <strong>LPPOM Majelis Ulama Indonesia </strong> declare that the
                (Enzyme) product stated as Halal according to the Islamic Law.
              </p>
              <p>
                We do our production with high quality standard an give bests
                quality product for all customer need.
              </p>
            </div>
          </div>
          <div className="halal__product-list">
            <h1 className="product-title">
              Our <strong> Halal Product</strong> List
            </h1>
            <HalalProductCards productData={productData} />
          </div>
        </div>
      </div>
      <WhatsappContact />
      <Footer />
    </div>
  );
}

export default HalalPage;
