import styled from "styled-components";
import { lightBlue, darkBlue, lightGray, black } from "../color";
import { Link } from "react-router-dom";

const OvalButton = styled.button`
  background: ${(props) => (props.primary ? lightBlue : darkBlue)};
  height: 35px;
  color: #fff;
  margin-top: 5px;
  margin-bottom: 5px;
  border-color: transparent;
  border-radius: 50px;
  font-weight: 600;
`;

let productDescription = {
  paddingRight: "15px",
  paddingLeft: "15px",
};

function ProductAtttribute(props) {
  const name = props.name;
  const value = props.value;
  const style = {
    color: black,
    backgroundColor: lightGray,
    borderRadius: 50,
    marginTop: "10px",
    marginBottom: "15px",
    height: "40px",
    fontWeight: 600,
  };

  return (
    <div style={style}>
      <p style={{ marginLeft: "20px", paddingTop: "7px" }}>
        {name}
        <span style={{ marginRight: "20px", float: "right", color: lightBlue }}>
          {value}
        </span>
      </p>
    </div>
  );
}

function ProductDetail(props) {
  const detail = props.detail;
  const properties = props.properties;
  return (
    <div className="product">
      <div className="row">
        <p style={{ fontWeight: "600" }}>
          {detail.categories} >{" "}
          <span style={{ color: "rgb(1 131 197)" }}> {detail.productId} </span>
        </p>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span
            aria-hidden="true"
            style={{
              color: "white",
              boxShadow: "black",
              borderRadius: "50%",
              paddingRight: "6px",
              paddingLeft: "6px",
              backgroundColor: "black",
            }}
          >
            X
          </span>
        </button>
      </div>
      <div className="row">
        <div className="product__image col-sm-12 col-md-4 pad-t-15 flex flex-jc-c">
          <img src={detail.productImage} alt="product" />
        </div>
        <div className="product__detail col-sm-12 col-md-8 pad-t-15">
          <h3 className="product__name"> {detail.name} </h3>
          <packaging className="product__product-id mar-b-20">
            {detail.productId}
          </packaging>
          <p className="product__desc mar-b-20"> {detail.description} </p>
          <ProductAtttribute name="Price" value={detail.price} />
          <ProductAtttribute name="Min Quantity" value={detail.minQuantity} />
          <ProductAtttribute name="Sub category" value={detail.subcategories} />

          {detail.downloadLink !== "-" && (
            <Link
              to={detail.downloadLink}
              style={{ color: "white" }}
              target="_blank"
              download
            >
              <OvalButton className="col-xs-12 col-sm-12 col-md-5">
                Download PDF
              </OvalButton>
            </Link>
          )}

          {detail.downloadLink !== "-" && <div className="col-md-2"></div>}
          <Link
            to={`/request-quotation?productId=${detail.productId}`}
            target="_blank"
            style={{ color: "white", textDecoration: "none" }}
          >
            <OvalButton className="col-xs-12 col-sm-12 col-md-5" primary>
              Request a quote
            </OvalButton>
          </Link>
        </div>
      </div>
      <div className="row pad-t-20">
        <h4 className="product__description_title" style={productDescription}>
          Product Description
        </h4>
        <p style={productDescription}> Properties </p>
        <div className="flex flex-jc-sb col-sm-12 col-md-6">
          <ul>
            <li>
              <p>
                <b>Appearance</b>
              </p>
              <p>
                <b>Density</b>
              </p>
              <p>
                <b>pH Enzyme</b>
              </p>
              <p>
                <b>Solubility</b>
              </p>
            </li>
          </ul>
          <ul>
            <li>
              <p>{properties.appearance}</p>
              <p>{properties.density}</p>
              <p>{properties.phEnzyme}</p>
              <p>{properties.solubility}</p>
            </li>
          </ul>
        </div>
        <div className="col-sm-12 col-md-6">
          <ul>
            <li>
              <p>
                <b>Packaging:</b> {properties.packaging}
              </p>
              <p>
                <b>Weight:</b> {properties.weight}
              </p>
              <p>
                <b>Expire:</b> {properties.expire}
              </p>
              <p>
                <b>Storage handling:</b> {properties.storageHandling}
              </p>
            </li>
          </ul>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default ProductDetail;
