function ProductCard(props) {
  const detail = props.detail;
  const isHalal = props.halal;

  return (
    <div className="card">
      {isHalal === "Halal" && (
        <img
          src="/logo/halal.png"
          alt="Halal Product"
          className="card__halal"
        />
      )}

      <div className="card__image">
        <img
          src="/img/products/product.png"
          alt={`Product: ${detail.subcategories}`}
        />
        <div className="card__id font__700">{detail.productId}</div>
      </div>
      <div className="card__name pad-t-15 font__500">{detail.name}</div>
    </div>
  );
}

export default ProductCard;
