import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

export default function WhatsappContact(props) {
  let style = {
    width: "100px",
  };

  let containerStyle = {
    position: "fixed",
    right: "0",
    bottom: "6rem",
    backgroundColor: "#128C7E",
    color: "white",
    borderTopLeftRadius: "25px",
    borderBottomLeftRadius: "25px",
    padding: "0.4rem 0.6rem",
    zIndex: "10",
  };

  return (
    <div style={containerStyle}>
      <a
        style={style}
        href="https://api.whatsapp.com/send?phone=6287845749910&text=Hallo%20PT.%20Sadya%20Balawan..."
      >
        <FontAwesomeIcon
          icon={faWhatsapp}
          size="1x"
          className="icon"
          style={{ color: "white" }}
        />
        <span
          style={{ color: "white", paddingLeft: "0.5rem", fontWeight: "600" }}
        >
          How can we help you ?
        </span>
      </a>
    </div>
  );
}
