export default function HexagonProcess(props) {
  return (
    <div className="list-hex-grid">
      <div className="contents" style={{ paddingBottom: "2rem" }}>
        <p>
          We have several proven successful application in industrial process
          such as:
        </p>
      </div>
      <div className="first-grid">
        <div className="hex grid grid-3">
          <div className="hex-title">Paper mill </div>
          <div className="hex-contents" id="hex-paper-mill">
            <div className="hex-1"></div>
            <div className="hex-2"></div>
          </div>
        </div>
        <div className="hex grid grid-3">
          <div className="hex-title">Sugar mill </div>
          <div className="hex-contents" id="hex-sugar-mill">
            <div className="hex-1"></div>
            <div className="hex-2"></div>
          </div>
        </div>
        <div className="hex grid grid-3">
          <div className="hex-title"> Ceramic Tile </div>
          <div className="hex-contents" id="hex-ceramic-tile">
            <div className="hex-1"></div>
            <div className="hex-2"></div>
          </div>
        </div>
      </div>
      <div className="second-grid">
        <div className="hex grid grid-3">
          <div className="hex-title">Sugar Refinery mill </div>
          <div className="hex-contents" id="hex-sugar-refinery-mill">
            <div className="hex-1"></div>
            <div className="hex-2"></div>
          </div>
        </div>
        <div className="hex grid grid-3">
          <div className="hex-title">Detergent </div>
          <div className="hex-contents" id="hex-detergent">
            <div className="hex-1"></div>
            <div className="hex-2"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
