import Navbar from "../header/navbar";
import Footer from "../footer/footer";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ScrollAnimation from "react-animate-on-scroll";
import React, { useState, useRef, useEffect } from "react";
import WhatsappContact from "../utils/whatsapp";

function useHover() {
  const [value, setValue] = useState(false);
  const ref = useRef(null);
  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);
  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);
        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );
  return [ref, value];
}

function AboutUsComponent(props) {
  return (
    <ScrollAnimation animateIn="fadeInUp" duration={1} animateOnce={true}>
      <section className="intro section-container">
        <div className="intro__container">
          <div className="intro__left">
            <img src="img/home/about_us.png" alt="About Us" />
          </div>
          <div className="intro__right">
            <div className="contents">
              <h1> Who we are </h1>
              <p>
                We are leading company that provides high quality manufactures
                of Chemical and Enzyme / Bio-product.
              </p>
              <p style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                We are here to help you succeed with your business goals.
              </p>

              <Link to="/about">
                <button className="btn btn-border-50 btn-home btn-border-width-2">
                  More About Us
                </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </ScrollAnimation>
  );
}

function ProductServiceApplication(props) {
  const [hoverRef, isHovered] = useHover();

  const image = props.image;
  const title = props.title;
  const link = props.link;
  return (
    <div ref={hoverRef} className="product-service__application__container">
      <Link to={`/products/${link}`}>
        <ScrollAnimation animateIn="flipInX" animateOnce={true}>
          <div className="product-service__application__image">
            <img src={image} alt={title} />
          </div>
        </ScrollAnimation>
        <div
          className="product-service__application__title"
          style={
            isHovered
              ? { border: "2px solid #0183c5", color: "#0183c5" }
              : { border: "2px solid #726d6d", color: "#726d6d" }
          }
        >
          {" "}
          {title} <br></br>
          Application
        </div>
      </Link>
    </div>
  );
}

function ProductServiceComponent(props) {
  return (
    <div className="product-service">
      <div className="product-service__container">
        <h4 className="product-service__sub-title">
          Innovator of Specialty Chemical and Bio-product
        </h4>
        <h2 className="product-service__title">
          Our <span style={{ fontWeight: "700" }}>Product & Service</span>
        </h2>
      </div>
      <div className="product-service__application">
        <ProductServiceApplication
          image="/img/home/sugar.png"
          title="Sugar"
          link="enzyme-for-sugar-industry"
        />
        <ProductServiceApplication
          image="/img/home/microscope.png"
          title="Paper"
          link="enzyme-for-paper-industry"
        />
        <ProductServiceApplication
          image="/img/home/feed.png"
          title="Other"
          link="feed-additive"
        />
      </div>
      <p style={{ marginLeft: "20px", marginRight: "20px" }}>
        We focus in specific program applications that deliver significant
        values.
      </p>
      <p style={{ marginLeft: "20px", marginRight: "20px" }}>
        It is a combinations of great product an expertise on application.
      </p>
    </div>
  );
}

function ContactUsComponent(props) {
  return (
    <ScrollAnimation animateIn="fadeInLeft" duration={1} animateOnce={true}>
      <div className="contact-us">
        <div className="contact-us__left">
          <div className="image-clip">
            <img
              src="img/home/factory_workers.png"
              alt="factory worker"
              style={{ width: "90%" }}
            />
          </div>
        </div>
        <div className="contact-us__right">
          <p>
            We’d like to discuss how our product can help your business grow.{" "}
          </p>
          <Link to="/contact">
            <button className="btn btn-border-50 btn-home btn-border-width-2">
              Contact Us
            </button>
          </Link>
        </div>
      </div>
    </ScrollAnimation>
  );
}
function CarouselHero(props) {
  const mobileWidth = 900;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < mobileWidth;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <div
      id="carouselcontrols"
      className="carousel slide"
      data-ride="carousel"
      data-interval="3000"
    >
      <ol className="carousel-indicators">
        <li
          data-target="#carouselcontrols"
          data-slide-to="0"
          className="active"
        ></li>
        <li
          data-target="#carouselcontrols"
          data-slide-to="1"
          style={{ marginRight: "15px", marginLeft: "15px" }}
        ></li>
        <li data-target="#carouselcontrols" data-slide-to="2"></li>
      </ol>

      <div className="carousel-inner">
        <div className="item active">
          <img
            style={{ width: "100%" }}
            className="d-block w-100"
            src={`${
              isMobile
                ? "./img/home/hero_office_small.png"
                : "./img/home/hero_office_mid.png"
            }`}
            alt="Saba Office"
          />
        </div>
        <div className="item">
          <img
            style={{ width: "100%" }}
            className="d-block w-100"
            src={`${
              isMobile
                ? "./img/home/hero_factory_small.png"
                : "./img/home/hero_factory_mid.png"
            }`}
            alt="Factory"
          />
        </div>
        <div className="item">
          <img
            style={{ width: "100%" }}
            className="d-block w-100"
            src={`${
              isMobile
                ? "./img/home/hero_paper_mill_small.png"
                : "./img/home/hero_paper_mill_mid.jpg"
            }`}
            alt="Paper Mill"
          />
        </div>
      </div>
    </div>
  );
}

function StatisticSection(props) {
  const CounterUp = ({ ...rest }) => {
    const [viewPortEntered, setViewPortEntered] = useState(false);

    return (
      <CountUp {...rest} start={viewPortEntered ? null : 0}>
        {({ countUpRef }) => {
          return (
            <VisibilitySensor
              active={!viewPortEntered}
              onChange={(isVisible) => {
                if (isVisible) {
                  setViewPortEntered(true);
                }
              }}
              delayedCall
            >
              <h1 ref={countUpRef} />
            </VisibilitySensor>
          );
        }}
      </CountUp>

      // <CountUp
      //   end={props.number}
      //   duration="3"
      //   onStart={onStart}
      //   onEnd={onEnd}
      //   containerProps={containerProps}
      // />
    );
  };
  return (
    <div className="stats">
      <div className="stats__cards" id="card1">
        <CounterUp duration="2" end={470000} />
        <p>Paper Treated: Ton / Years</p>
      </div>
      <hr className="stats__divider" />
      <div className="stats__cards" id="card2">
        <CounterUp duration="2" end={11} />
        <p>Years In Business</p>
      </div>
      <hr className="stats__divider" />
      <div className="stats__cards" id="card3">
        <CounterUp duration="2" end={200000} />
        <p>Sugar treated: Ton / Years</p>
      </div>
    </div>
  );
}

function ArticleSection(props) {
  return (
    <div className="article">
      <div className="article__container">
        <div className="article__title">
          Our <span>Case Studies</span>
        </div>
        <div className="article__contents">
          <div className="article__cards" id="article1">
            <div className="card-title">
              Increasing the production of the Bioethanol using Enzyme
            </div>
            <div className="card-button">
              <a
                href="https://www.facebook.com/sadyabalawan/posts/366961608424664"
                target="_blank"
                style={{ color: "white" }}
                rel="noreferrer"
              >
                Find Out More
              </a>
            </div>
          </div>
          <div className="article__cards" id="article2">
            <div className="card-title">
              Enzyme &alpha; - Amylase as starch sizing modification
            </div>
            <div className="card-button">
              <a
                href="https://www.facebook.com/sadyabalawan/posts/357564219364403"
                target="_blank"
                style={{ color: "white" }}
                rel="noreferrer"
              >
                Find Out More
              </a>
            </div>
          </div>
          <div className="article__cards" id="article3">
            <div className="card-title">Sizing Agent Papermaking</div>
            <div className="card-button">
              <a
                href="https://www.facebook.com/sadyabalawan/posts/340065387780953"
                target="_blank"
                style={{ color: "white" }}
                rel="noreferrer"
              >
                Find Out More
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function HomePage(props) {
  return (
    <div className="container-fluid">
      <Navbar />
      <CarouselHero />
      <AboutUsComponent />
      <ProductServiceComponent />
      <StatisticSection />
      <ArticleSection />
      <ContactUsComponent />
      <WhatsappContact />
      <Footer />
    </div>
  );
}

export default HomePage;
