import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faPhoneAlt);

function Footer() {
  return (
    <footer className="footer">
      {/* <div className="footer__left col-sm-12 col-md-6 pad-t-60 flex flex-jc-start"> */}
      <div className="footer__left col-sm-12 col-md-6 flex flex-jc-start">
        <img
          className="footer__logo"
          src="/logo/logo-white.png"
          alt="PT. Sadya Balawan"
        />
        <div className="address">
          <h4>PT. SADYA BALAWAN (SABA)</h4>
          <p>
            Kawasan Industri Tengsaw, Jl. Alternatif Tarikolot – Sukahati,
            Citeureup – Bogor, Jawa Barat Indonesia, 16810.
          </p>
          <p style={{ fontWeight: 500 }}>Email : marketing@saba.co.id</p>
        </div>
      </div>
      <div className="footer__right contact col-sm-12 col-md-6 flex">
        <div className="contact__logo flex-ai-c">
          <FontAwesomeIcon icon={faPhoneAlt} size="3x" />
          <p className="text-center"> Phone </p>
        </div>
        <div className="contact__phone">
          <p>(+62) 021 2962 9417 </p>
          <p>(+62) 0878 4574 9910</p>
          <p>(+62) 021 2962 9418</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
