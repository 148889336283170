export const productData = [
  {
    number: 1,
    productId: "CATO 9168",
    categories: "Pulp And Paper Application",
    subcategories: "Chemical for Paper Industry",
    halal: "No",
    productImage: "/img/products/items/CATO-9168.png",
    minQuantity: 1,
    name: "Cationic Tapioca Starch Ex Thailand 25KG",
    price: "contact us",
    deskripsi: "",
    description:
      "CATO 9168 is a specialty cooked-up tapioca starch, made from a selective cationization manufacturing process to enhance the cationic charge uniformity of polymeric hydrocarbon chains. It is used in the papermaking industry as wet end dry strength agent, drainage and retention aids.",
    buyLink:
      "http://en.sadya-balawan.com/product/cationic-tapioca-starch-cato-9168-ex-thailand-25kg-p918131.aspx",
    downloadLink: "-",
    properties: {
      appearance: "white",
      density: "-",
      phEnzyme: "5.0 - 8.0",
      solubility: "-",
      packaging: "-",
      weight: "-",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 2,
    productId: "SB-R06",
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-R06.png",
    minQuantity: 1,
    name: "Liquid Refining Enzyme",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-R06 is a liquid hydrolytic enzyme for application to refining paper of virgin and recycle fiber. The enzyme will selectively fibrillate fibers that facilitate subsequent improvement in physical factors, such as freeness and better dewatering ability without any loss in the mechanical strength of the paper.",
    buyLink:
      "http://en.sadya-balawan.com/product/liquid-refining-enzyme-seri-sb-r06-p891488.aspx",
    downloadLink: "/pds/enzymes-paper/PDS-SB-R-06-Halal.pdf",
    properties: {
      appearance: "light brown liquid",
      density: "1.0150 - 1.0550 g/ml",
      phEnzyme: "3.5 - 6.5",
      solubility: "-",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "6 month at 32 C",
      storageHandling: "Cooler condition are better.",
    },
  },
  {
    number: 3,
    productId: "SB-AM04",
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-AM04.png",
    minQuantity: 1,
    name: "Enzyme Amilase  Sizing Agent Paper 5 kg",
    price: "contact us",
    deskripsi: "",
    description:
      "SBAM-04 is a liquid enzyme for preparing a starch based composition, perfectly for use in paper sizing and/or coating compositions. SBAM-04 hydrolyze starch randomly that result in rapid reduction of viscosity of gelatinized starch.",
    buyLink:
      "http://en.sadya-balawan.com/product/amilase-enzyme-sizing-agent-sb-am04-p773839.aspx",
    downloadLink: "/pds/enzymes-paper/PDS-SB-AM-04-Halal.pdf",
    properties: {
      appearance: "Brown liquid",
      density: "1.0500 - 1.2000 g/ml",
      phEnzyme: "4.0 - 7.0",
      solubility: "Soluble in water",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "6 months at 25 C / 1 year 10 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 4,
    productId: "SB-AM04",
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-AM04.png",
    minQuantity: 1,
    name: "Enzyme Amilase Sizing Agent Paper 30 kg",
    price: "contact us",
    deskripsi: "",
    description:
      "SBAM-04 is a liquid enzyme for preparing a starch based composition, perfectly for use in paper sizing and/or coating compositions. SBAM-04 hydrolyze starch randomly that result in rapid reduction of viscosity of gelatinized starch.",
    buyLink:
      "http://en.sadya-balawan.com/product/amilase-enzyme-sizing-agent-sb-am04-p773839.aspx",
    downloadLink: "/pds/enzymes-paper/PDS-SB-AM-04-Halal.pdf",
    properties: {
      appearance: "Brown liquid",
      density: "1.0500 - 1.2000 g/ml",
      phEnzyme: "4.0 - 7.0",
      solubility: "Soluble in water",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "7 months at 25 C / 1 year 10 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 5,
    productId: "SB-DS05",
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-DS05.png",
    minQuantity: 1,
    name: "Enzyme Color Spot Removal - Pulp & Paper",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-DS05 is a liquid enzyme for removing color spots (contaminant) residue both water soluble inks from recycle fiber. It is an excellent aid to improve color-contaminant removal. SB-DS05 also significantly improves pulp brightness in low quality recovered paper.",
    buyLink:
      "http://en.sadya-balawan.com/product/enzim-color-spot-removal-pulp-paper-sb-ds-05-halal-p780751.aspx",
    downloadLink: "/pds/enzymes-paper/PDS-SB-DS-05-Halal.pdf",
    properties: {
      appearance: "Yellowish to brownish liquid",
      density: "1.0150 - 1.0550 g/ml",
      phEnzyme: "3.5 - 6.5",
      solubility: "-",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "6 month at 32 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 6,
    productId: "SB-SC25",
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-SC25.png",
    minQuantity: 1,
    name: "Enzyme Sticky Control Papermaking",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-SC25 is a liquid enzyme for specifically attacks sticky materials on paper machine. It  reduce paper machine breaks or downtime and eliminate sticky materials found in the sheet giving more efficient operation. And also reduce the use of harsh chemical solvent for cleaning from stickies.",
    buyLink:
      "http://en.sadya-balawan.com/product/enzim-sticky-control-sb-sc-25-p836875.aspx",
    downloadLink: "/pds/enzymes-paper/PDS-SB-SC-25-Halal.pdf",
    properties: {
      appearance: "Yellowish to Tannish liquid",
      density: "1.0150 - 1.0850 g/ml",
      phEnzyme: "3.0 - 6.5",
      solubility: "-",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "3 month at 25 C / 5 month at 15 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 7,
    productId: "SB-D05",
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-D05.png",
    minQuantity: 1,
    name: "Deinking Enzyme - Papermaking",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-D05 is a liquid enzyme for application to water base deinking recycled pulp. This enzyme improves ink separation from fiber by attack ink and fiber interface. This result in better ink release and less ink deposition back to fiber.",
    buyLink:
      "http://en.sadya-balawan.com/product/deinking-enzim-sb-d05-halal-p773849.aspx",
    downloadLink: "/pds/enzymes-paper/PDS-SB-D-05-Halal.pdf",
    properties: {
      appearance: "light brown liquid",
      density: "1.0100 - 1.1000 g/ml",
      phEnzyme: "3.00 - 7.50",
      solubility: "-",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "6 month at 32 C",
      storageHandling: "Cooler condition are better.",
    },
  },
  {
    number: 8,
    productId: "SB-RV07",
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-RV07.png",
    minQuantity: 1,
    name: "Refining Enzyme - Revitalization",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-RV07 is a liquid hydrolytic enzyme for revitalitation of recycle paper. The enzyme will selectively fibrillate fibers that facilitate subsequent improvement in physical factors, such as better dewatering water (Drainage) ability without any loss in the mechanical strength of the paper.",
    buyLink:
      "http://en.sadya-balawan.com/product/refiningrevitalization-agent-enzyme-sb-rv07-p773845.aspx",
    downloadLink: "/pds/enzymes-paper/PDS-SB-RV-07-Halal.pdf",
    properties: {
      appearance: "light brown liquid",
      density: "1.0000 - 1.1000 g/m",
      phEnzyme: "3.5 - 6.5",
      solubility: "-",
      packaging: "plastic jerry can",
      weight: "30 kg plastic jerry can",
      expire: "3 month at 25 C / 6 month at 15 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 9,
    productId: "SB-CHEM 9201",
    categories: "Pulp And Paper Application",
    subcategories: "Chemical for Paper Industry",
    halal: "No",
    productImage: "/img/products/items/SB-CHEM9201.png",
    minQuantity: 1,
    name: "Surfactants Chem 9201",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-CHEM-9201 is a blend of fatty ester derivate and surfactants designed to provide foaming performance. It has been developed to generate foam in the deinking process of waste paper like newsprint paper, and magazines, etc.",
    buyLink:
      "http://en.sadya-balawan.com/product/surfaktan-industri-chem-9201-p773902.aspx",
    downloadLink: "/pds/chemical/PDS-SB-Chem-9201.pdf",
    properties: {
      appearance: "Yellowish to amber liquid",
      density: "-",
      phEnzyme: "6.5 - 8.5",
      solubility: "Water dispersible",
      packaging: "Plastic pails / Drums",
      weight: "25 Kgs plastic pails or 200 Kgs in drum.",
      expire: "1 Year at 32 C",
      storageHandling: "Dry place.",
    },
  },
  {
    number: 10,
    productId: "SB-1451",
    categories: "Pulp And Paper Application",
    subcategories: "Chemical for Paper Industry",
    halal: "No",
    productImage: "/img/products/items/SB-1451.png",
    minQuantity: 1,
    name: "Cationic Polymer Flocculant Industrial Grade",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-1451 is high molecular weight cationic polymer and effective for wide range of application and variety of industries.",
    buyLink:
      "http://en.sadya-balawan.com/product/cationic-polymer-flocculant-industrial-grade-sb-1451-p912616.aspx",
    downloadLink: "/pds/chemical/Prod-Buletin-SB-1451.pdf",
    properties: {
      appearance: "Off white, granular powder",
      density: "700 - 800 kg/m3",
      phEnzyme: "3.0 - 5.0",
      solubility: "-",
      packaging: "bag",
      weight: "25 kg bag",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 11,
    productId: "SB-1351 AF",
    categories: "Pulp And Paper Application",
    subcategories: "Chemical for Paper Industry",
    halal: "No",
    productImage: "/img/products/items/SB-1351AF.png",
    minQuantity: 1,
    name: "Anionic Flocculant Polymer Industrial Grade",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-1351 AF is used mainly for flocculation in the treatment of raw water from the surface and ground water, and all types of waste water, after preliminary treatment with hydroxide forming substances.",
    buyLink:
      "http://en.sadya-balawan.com/product/anionic-flocculant-polymer-industrial-grade-sb-1351-af-p912629.aspx",
    downloadLink: "/pds/chemical/Prod-Buletin-SB-1351-AF.pdf",
    properties: {
      appearance: "Off white, granular powder",
      density: "0.75 - 0.9 g/cm3",
      phEnzyme: "5.0 - 7.0",
      solubility: "-",
      packaging: "sack",
      weight: "25 kg sack",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 12,
    productId: "SB-A1.0",
    categories: "Sugar Processing Application",
    subcategories: "Enzyme for Sugar Industry",
    halal: "Halal",
    productImage: "/img/products/items/SB-A1.0.png",
    minQuantity: 1,
    name: "Enzyme Alpha Amilase",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-A1.0 is a liquid enzyme for enzymatic liquefaction of starch to yield soluble oligosaccharides. SB-A1.0 hydrolyses randomly the ?-1,4 glycosidic bonds in amylose and amylopectin, thus providing better clarification, better sugar quality, and higher sugar yield.",
    buyLink:
      "http://en.sadya-balawan.com/product/alpha-amylase-enzyme-sb-a10-sugar-p891478.aspx",
    downloadLink: "/pds/sugar-paper/PDS-SB-A1.0-Halal.pdf",
    properties: {
      appearance: "Dark Brown liquid",
      density: "1.0500 - 1.2000 g/ml",
      phEnzyme: ".3.5 - 7.5",
      solubility: "Soluble in water",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "6 months at 25 C / 1 year 10 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 13,
    productId: "FC-DX1.0",
    categories: "Sugar Processing Application",
    subcategories: "Enzyme for Sugar Industry",
    halal: "Halal",
    productImage: "/img/products/items/FC-DX1.0png",
    minQuantity: 1,
    name: "Enzyme Dextranase Food Grade for Sugar Industry",
    price: "contact us",
    deskripsi: "",
    description:
      "FC-DX1.0 is a high performing dextranase enzyme produced from a non-GMO strain of chaetomium gracile used for manufacturing of cane sugar, toothpaste, etc. The declared activity is 10.000 units.",
    buyLink:
      "http://en.sadya-balawan.com/product/enzim-dextranase-food-grade-industri-gula-fc-dx10-halal-p780726.aspx",
    downloadLink: "/pds/sugar-paper/PDS-FC-DX1.0-Halal.pdf",
    properties: {
      appearance: "Dark Brown liquid",
      density: "1.0000 - 1.2000 g/ml",
      phEnzyme: "4.0 - 7.0",
      solubility: "Soluble in water",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "6 months at 25 C / 1 year 10 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 14,
    productId: "MELASIL SB-20",
    categories: "Sugar Processing Application",
    subcategories: "Chemical for Sugar Industry",
    halal: "No",
    productImage: "/img/products/items/MELASILSB-20.png",
    minQuantity: 1,
    name: "Defoamer Melasil SB-20 Silicon Base 30 KG",
    price: "contact us",
    deskripsi: "",
    description:
      "MELASIL SB-20 is a low cost liquid silicone-based\ndefoamer which offers superior deaeration,\ndrainage and cost effectiveness over many\nconventional Silicone based defoamers",
    buyLink:
      "http://en.sadya-balawan.com/product/defoamer-anti-foam-basis-silikon-p908282.aspx",
    downloadLink: "/pds/sugar-paper/PDS-MELASIL-SB-20.pdf",
    properties: {
      appearance: "Fluid White Emulsion",
      density: "-",
      phEnzyme: "6.0 - 9.0",
      solubility: "Water dispersible",
      packaging: "plastic drum / netmini bul container",
      weight: "20kg/200 kg plastic drums / 1000 kg netmini bulk containers.",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 15,
    productId: "MELASIL SB-20",
    categories: "Sugar Processing Application",
    subcategories: "Chemical for Sugar Industry",
    halal: "No",
    productImage: "/img/products/items/MELASILSB-20.png",
    minQuantity: 1,
    name: "Defoamer Melasil SB-20 Silicon Base 200 KG",
    price: "contact us",
    deskripsi: "",
    description:
      "MELASIL SB-20 is a low cost liquid silicone-based\ndefoamer which offers superior deaeration,\ndrainage and cost effectiveness over many\nconventional Silicone based defoamers",
    buyLink:
      "http://en.sadya-balawan.com/product/defoamer-anti-foam-basis-silikon-p908282.aspx",
    downloadLink: "/pds/sugar-paper/PDS-MELASIL-SB-20.pdf",
    properties: {
      appearance: "Fluid White Emulsion",
      density: "-",
      phEnzyme: "6.0 - 9.0",
      solubility: "Water dispersible",
      packaging: "plastic drum / netmini bul container",
      weight: "20kg/200 kg plastic drums / 1000 kg netmini bulk containers.",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 16,
    productId: "e-BE 1.0",
    categories: "Other Application",
    subcategories: "Bioethanol Application",
    halal: "No",
    productImage: "/img/products/items/E-BE1.0.png",
    minQuantity: 1,
    name: "Liquid Bioethanol Enzyme",
    price: "contact us",
    deskripsi: "",
    description:
      "e-BE 1.0 is consortium of enzymes specialized for enhancing productivity of ethanol production. Enzymes hydrolize unfermentable sugars in raw material (molasses) to become fermentable sugars. Higher fermentable sugars results higher yield of ethanol.",
    buyLink:
      "http://en.sadya-balawan.com/product/liquid-bioethanol-enzyme-e-be-10-p891476.aspx",
    downloadLink: "-",
    properties: {
      appearance: "Dark Brown liquid",
      density: "1.0000 - 1,2000 g/ml",
      phEnzyme: "3.0 - 6.0",
      solubility: "Soluble in water",
      packaging: "plastic jerry can/ plastic drum",
      weight: "30 kg plastic jerry can / 200 kg plastic drum",
      expire: "6 months at 25 C / 1 year 10 C",
      storageHandling: "Cool and dry conditions are recommended.",
    },
  },
  {
    number: 17,
    productId: "SB-1351 AF",
    categories: "Other Application",
    subcategories: "Waste Water Treatment",
    halal: "No",
    productImage: "/img/products/items/SB-1351AF.png",
    minQuantity: 1,
    name: "Anionic Flocculant Polymer Industrial Grade",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-1351 AF is used mainly for flocculation in the treatment of raw water from the surface and ground water, and all types of waste water, after preliminary treatment with hydroxide forming substances.",
    buyLink:
      "http://en.sadya-balawan.com/product/anionic-flocculant-polymer-industrial-grade-sb-1351-af-p912629.aspx",
    downloadLink: "/pds/chemical/Prod-Buletin-SB-1351-AF.pdf",
    properties: {
      appearance: "Off white, granular powder",
      density: "0.75 - 0.9 g/cm3",
      phEnzyme: "5.0 - 7.0",
      solubility: "-",
      packaging: "sack",
      weight: "25 kg sack",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 18,
    productId: "SB-1451",
    categories: "Other Application",
    subcategories: "Waste Water Treatment",
    halal: "No",
    productImage: "/img/products/items/SB-1451.png",
    minQuantity: 1,
    name: "Cationic Polymer Flocculant Industrial Grade",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-1451 is high molecular weight cationic polymer and effective for wide range of application and variety of industries.",
    buyLink:
      "http://en.sadya-balawan.com/product/cationic-polymer-flocculant-industrial-grade-sb-1451-p912616.aspx",
    downloadLink: "/pds/chemical/Prod-Buletin-SB-1451.pdf",
    properties: {
      appearance: "Off white, granular powder",
      density: "700 - 800 kg/m3",
      phEnzyme: "3.0 - 5.0",
      solubility: "-",
      packaging: "sack",
      weight: "25 kg sack",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 19,
    productId: "SB-1392 CF",
    categories: "Other Application",
    subcategories: "Waste Water Treatment",
    halal: "No",
    productImage: "/img/products/items/SB-1392CF.png",
    minQuantity: 1,
    name: "Cationic Polymer Flocculant Industrial Grade",
    price: "contact us",
    deskripsi: "",
    description:
      "SB - 1392 CF is high molecular weight cationic flocculant polymer and effective for wide range of application and variety of industries.",
    buyLink:
      "http://en.sadya-balawan.com/product/cationic-polymer-flocculant-industrial-grade-sb-1392-cf-p912605.aspx",
    downloadLink: "-",
    properties: {
      appearance: "Off white, granular powder",
      density: "650 - 900 kg/m3",
      phEnzyme: "3.0 - 6.0",
      solubility: "-",
      packaging: "sack",
      weight: "25 kg sack",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 20,
    productId: "e-Wash 1.0",
    categories: "Other Application",
    subcategories: "Enzyme for Laundry and Textile",
    halal: "No",
    productImage: "/img/products/items/E-WASH1.0.png",
    minQuantity: 1,
    name: "e-Wash 1.0 Detergent & Laundry Enzyme",
    price: "contact us",
    deskripsi: "",
    description:
      "e-Wash 1.0 is a complex blend of number of enzyme types of product specially designed for detergent industry, It is in liquid form and can be operating in wide range of temperature and in neutral pH conditions.",
    buyLink:
      "http://en.sadya-balawan.com/product/e-wash-10-enzim-deterjen-laundry-p947305.aspx",
    downloadLink: "-",
    properties: {
      appearance: "-",
      density: "-",
      phEnzyme: "-",
      solubility: "-",
      packaging: "-",
      weight: "-",
      expire: "-",
      storageHandling: "-",
    },
  },
  {
    number: 22,
    productId: "SB-FXL 10",
    categories: "Other Application",
    subcategories: "Feed Additive",
    halal: "No",
    productImage: "/img/products/items/SB-FXL10.png",
    minQuantity: 1,
    name: "Feed Enzyme Xylanase Liquid",
    price: "contact us",
    deskripsi: "",
    description:
      "SB-FXL 10 is a novel blend of liquid enzymes designed for the swine and poultry industry. It takes care of various problem related to feed indigestion such as the effect caused due to the presence of NSP'S and anti- nutritional factors present in the feedstuff like certain plant residues, which cannot be digested due to lack of endogenous enzymes. SB-FXL 10 is designed and useful for diet based on Corn-soya, Sorghum- soya or feed containing wheat, triticale, rye.",
    buyLink:
      "http://en.sadya-balawan.com/product/sb-fxl-10-enzim-pakan-unggas-xylanase-liquid-p930555.aspx",
    downloadLink: "-",
    properties: {
      appearance: "-",
      density: "-",
      phEnzyme: "-",
      solubility: "-",
      packaging: "-",
      weight: "-",
      expire: "-",
      storageHandling: "-",
    },
  },
];
