import Navbar from "../header/navbar";
import Footer from "../footer/footer";
import { EMAIL } from "../utils/email";
import { ScrollToTopOnMount } from "../scroll";
import WhatsappContact from "../utils/whatsapp";

import emailjs, { init } from "@emailjs/browser";
import { useForm } from "react-hook-form";
import React, { useState, useEffect, Fragment } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faFax,
  faMapMarker,
} from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as FacebookIcon } from "../../assets/logo/facebook.svg";
import { ReactComponent as LinkedinIcon } from "../../assets/logo/linkedin.svg";

function EmailModal(props) {
  const content = props.content;

  return (
    <div
      className="modal fade"
      id="emailStatus"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="emailStatusLabel"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title" id="emailStatusLabel">
              Email Status
            </h4>
          </div>
          <div className="modal-body"> {content} </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function ContactForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [emailStatus, setEmailStatus] = useState(
    "There is an error occured, please fixed it first before submitting the email"
  );

  const onSubmit = (data) => {
    init(atob(EMAIL.ID_USRE));
    console.log("trying to send email");

    const parameter = {
      subject: "Question",
      sender_name: data.name,
      company_name: data.company,
      email: data.email,
      message: data.message,
    };

    console.log(errors);
    if (Object.keys(errors).length === 0) {
      setEmailStatus("Please wait...");
    }

    emailjs
      .send(atob(EMAIL.ESIVRIE_DC), atob(EMAIL.ATTMPELI_IE), parameter)
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setEmailStatus("Your email already been sent");
        },
        function (error) {
          console.log("FAILED...", error);
          setEmailStatus(
            "There is something wrong with the server, please email us directly to marketing@saba.co.id"
          );
        }
      );
  };

  const errorMessageStyle = {
    color: "red",
    paddingLeft: "18px",
  };
  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)} className="contact__forms">
        <input
          type="text"
          className="input-form"
          name="name"
          placeholder="Name"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <span style={errorMessageStyle}>*Name field is required</span>
        )}
        <input
          type="email"
          className="input-form"
          name="email"
          placeholder="Email"
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        {errors.email && (
          <span style={errorMessageStyle}>*Email field is required</span>
        )}
        <input
          type="text"
          className="input-form"
          name="company"
          placeholder="Company"
          {...register("company", { required: true })}
        />
        {errors.company && (
          <span style={errorMessageStyle}>*Company name field is required</span>
        )}
        <textarea
          className="inputarea-form"
          name="message"
          placeholder="Message"
          rows="4"
          {...register("message", { required: true })}
        />
        {errors.message && (
          <span style={errorMessageStyle}>*Message field is required</span>
        )}
        <input
          id="btn-form-submit"
          className="btn btn-default btn-border-50"
          type="submit"
          data-toggle="modal"
          data-target="#emailStatus"
        />
      </form>
      <EmailModal content={emailStatus} />
    </Fragment>
  );
}

function ContactPage(props) {
  const mobileWidth = 601;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < mobileWidth;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <div className="container-fluid">
      <ScrollToTopOnMount />
      <Navbar currentPage="contact" />
      <div className="row hero-factory hero-margin">
        <img
          src={`${
            isMobile
              ? "img/contact/hero_contact_us_small.jpg"
              : "img/contact/hero_contact_us_mid.jpg"
          }`}
          alt="Sadya Factory"
          style={{ width: "100%" }}
        />
      </div>
      <div className="contact__container">
        <div className="contact__left">
          <div className="contact__left-container">
            <div className="section">
              <h4 className="contact__title">
                <FontAwesomeIcon icon={faPhoneAlt} size="1x" className="icon" />
                Call
              </h4>
              <p style={{ lineHeight: "1rem" }}>(+62) 021 2962 9417</p>
              <p>(+62) 0878 4574 9910</p>
            </div>
            <div className="section">
              <h4 className="contact__title">
                <FontAwesomeIcon icon={faFax} size="1x" className="icon" />
                FAX
              </h4>{" "}
              <p>(+62) 021 2962 9418</p>
            </div>
            <div className="section">
              <h4 className="contact__title">
                <FontAwesomeIcon icon={faEnvelope} size="1x" className="icon" />
                EMAIL
              </h4>{" "}
              <p>marketing@saba.co.id</p>
            </div>
            <div className="section">
              <h4 className="contact__title">
                {" "}
                <FontAwesomeIcon
                  icon={faMapMarker}
                  size="1x"
                  className="icon"
                />
                Location
              </h4>
              <p>
                PT. Sadya Balawan (SaBa) Kawasan Industri Tengsaw Jl. Alternatif
                Tarikolot – sukahati, Citeureup – Bogor, Jawa Barat 16810
                Indonesia.
              </p>
            </div>
            <div>
              <p style={{ fontWeight: "600" }}>Working hours:</p> Mon-Fri:
              8am-5pm
            </div>
            <div className="social-medias">
              <a href="https://www.facebook.com/sadyabalawan/">
                <FacebookIcon />
              </a>
              <a href="https://api.whatsapp.com/send?phone=6287845749910&text=Hallo%20PT.%20Sadya%20Balawan...">
                <img
                  src="/logo/whatsapp.png"
                  alt="Sadya Factory"
                  style={{ width: "32px", height: "32px" }}
                />
              </a>
              <a href="https://www.linkedin.com/company/sadya-balawan/">
                <LinkedinIcon />
              </a>
            </div>
            <div
              style={{
                backgroundColor: "white",
                display: "inline",
                marginTop: "-1px",
              }}
            ></div>
          </div>
        </div>
        <div className="contact__right">
          <p>Please leave us your question about SABA. </p>
          <p>We are here to help you succeed with your business goals.</p>
          <ContactForm />
          <h1 style={{ fontWeight: "600" }}> Google Maps </h1>
          <div className="contact__google-maps">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="Sadya Maps"
                  width="100%"
                  height="100%"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=sadya%20balawan&t=&z=13&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <WhatsappContact />
      <Footer />
    </div>
  );
}

export default ContactPage;
