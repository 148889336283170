import { List } from "immutable";
import React, { useState } from "react";
import Navbar from "../header/navbar";
import Footer from "../footer/footer";
import Sidebar from "../sidebar/sidebar";
import { sidebarSubcategory } from "../sidebar/subcategory";
// import SidebarMobile from "./sidebarMobile";
// import SidebarWeb from "./sidebarWeb";
import ProductCard from "../product/productCard";
import ProductDetail from "../product/productDetail";
import WhatsappContact from "../utils/whatsapp";

import { ScrollToTopOnMount } from "../scroll";
import { productData } from "../product/productData.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSlidersH, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { Outlet, useParams } from "react-router-dom";

library.add(faSlidersH, faCaretDown);

const content = {
  "Waste Water Treatment": {
    categories: "Other Application",
    subcategories: "Waste Water Treatment",
    link: "waste-water-treatment",
    detail: [
      "Solid waste in industries is the one of important issues for environment issues. Pulp & Paper, Palm Oil and Sugar Mill generally produce a huge amount of solid waste, while landfill cost becomes costly. Solid biomass waste actually could potentially be transformed into compost or energy. Our solutions for solid waste include the provision of technical recommendation and how to manage the solid waste becomes more useful.",
      "In order to ensure that the world is a safer and cleaner place for future generations to live in, there is an ever-increasing pressure to reduce waste and improve waste treatment from both a moral and legal perspective. We have a wealth of experience in reducing COD level in the waste water. We have range of chemicals and bi0-product for several industries which is very effective controlling COD level.",
      "We provides several solutions which has fitter to industries.",
    ],
  },
  "Enzyme for Paper Industry": {
    categories: "Pulp And Paper Application",
    subcategories: "Enzyme for Paper Industry",
    link: "enzyme-for-paper-industry",
    detail: [
      "Enzymes are proteins that act as biological catalysts. It is biological molecules that significantly speed up the rate of virtually of the chemical reactions.",
      "Cellulase, xylanase, laccase, amylase, and lipase are the most important enzymes that can be used in the pulp and paper processes. Usually, the purpose enzymes in paper industry like fiber modification (fibrillation) and bleaching boosting.",
    ],
  },
  "Chemical for Paper Industry": {
    categories: "Pulp And Paper Application",
    subcategories: "Chemical for Paper Industry",
    link: "chemical-for-paper-industry",
    detail: [
      "The group of Chemical are used for Paper Machine process can modify the properties of paper. These chemicals can be used to change the paper in many ways. It can be used in Stock Preparation process or paper machine process.",
      "Chemical product for Paper Industry have different purpose. In the Paper Machine purpose to changing paper color and brightness, or by increasing its strength and resistance to water. But chemical product usually use in other sector. For the example is Waste Water Treatment Plant.",
    ],
  },
  "Enzyme for Sugar Industry": {
    categories: "Sugar Processing Application",
    subcategories: "Enzyme for Sugar Industry",
    link: "enzyme-for-sugar-industry",
    detail: [
      "The sugar industry subsumes the production, processing and marketing of sugars (mostly saccharose and fructose). Sugar is made by extracted from Sugar cane and sugar beet. There are two types of Sugar industry. Plantation Sugar and Refinery Sugar.",
    ],
  },
  "Chemical for Sugar Industry": {
    categories: "Sugar Processing Application",
    subcategories: "Chemical for Sugar Industry",
    link: "chemical-for-sugar-industry",
    detail: [
      "The sugar industry subsumes the production, processing and marketing of sugars (mostly saccharose and fructose). Sugar is made by extracted from Sugar cane and sugar beet. There are two types of Sugar industry. Plantation Sugar and Refinery Sugar.",
    ],
  },
  "Bioethanol Application": {
    categories: "Other Application",
    subcategories: "Bioethanol Application",
    link: "bioethanol-application",
    detail: [],
  },
  "Enzyme for Laundry and Textile": {
    categories: "Other Application",
    subcategories: "Enzyme for Laundry and Textile",
    link: "enzyme-for-laundry-and-textile",
    detail: [
      "The function enzyme in Laundry are become a pre-washing dirty/stain removal treatment for release protein stains like blood, soil, mud, and the other. so it will help the process of regular washing and make more efficiently removed the dirt.",
    ],
  },
  "Feed Additive": {
    categories: "Other Application",
    subcategories: "Feed Additive",
    link: "feed-additive",
    detail: [
      "Feed additives are used for animal nutrition. The purposes from feed additive are improving the quality of feed and the quality of food from the animal. And it will be impacted for animal performance and health in Feed industry.",
    ],
  },
};

function filterProducts(products, subcategories) {
  return products.filter((data) => data.subcategories === subcategories).toJS();
}

function filterSubcategoryLink(link) {
  return sidebarSubcategory.filter((data) => data.link === link);
}

function Content(props) {
  const content = props.content;

  return (
    <main className="contents" style={{ paddingTop: "3rem" }}>
      <h3>{content.categories}</h3>
      <h1> {content.subcategories} </h1>
      {content.detail.map((val, i) => (
        <p key={i}>{val}</p>
      ))}
    </main>
  );
}

function ProductList(props) {
  const [currProductId, setCurrProductId] = useState(props.data[0]);

  return (
    <div>
      <div className="product-item">
        {props.data.map((item, idx) => {
          return (
            <div
              className="col-xs-6 col-sm-6 col-md-4 flex flex-jc-c"
              data-toggle="modal"
              data-target="#popupProduct"
              onClick={() => setCurrProductId(item)}
            >
              <ProductCard detail={item} key={item.number} halal={item.halal} />
            </div>
          );
        })}
      </div>
      <div
        className="modal fade out"
        id="popupProduct"
        tabIndex="-1"
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            {
              <ProductDetail
                detail={currProductId}
                properties={currProductId.properties}
              />
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export function ProductContent(props) {
  let { subcategory } = useParams();
  const subcategoryData = filterSubcategoryLink(subcategory);
  const products = filterProducts(
    List(productData),
    subcategoryData[0].subcategories
  );

  return (
    <div className="col-sm-12 col-md-8">
      <ScrollToTopOnMount />
      <Content content={content[subcategoryData[0].subcategories]} />
      <ProductList data={products} />
    </div>
  );
}

function ProductPage(props) {
  const [subcategory, setSubCategory] = useState("Chemical for Paper Industry");
  const [products, setProducts] = useState(
    filterProducts(List(productData), "Chemical for Paper Industry")
  );

  function onChangeSubCategory(subCategory) {
    setProducts(filterProducts(List(productData), subCategory));
    setSubCategory(subCategory);
  }
  return (
    <div className="container-fluid">
      <Navbar currentPage="products" />
      <div className="row hero-factory hero-margin">
        <img
          src="/img/home/hero_factory_mid.png"
          alt="Sadya Factory"
          style={{ width: "100%" }}
        />
      </div>
      <div className="product-container">
        {/* <div className="col-12"> */}
        <div className="row hide-for-desktop">
          <div className="col-12">
            <Sidebar
              submenuDropdown={false}
              menuDropdown={false}
              onChangeSubCategory={onChangeSubCategory}
              media={"mobile"}
            />
          </div>
        </div>
        <div className="row" style={{ marginRight: "0px" }}>
          <div className="col-sm-12 col-md-4 hide-for-mobile">
            <Sidebar
              submenuDropdown={true}
              menuDropdown={true}
              onChangeSubCategory={onChangeSubCategory}
              media={"web"}
            />
          </div>
          <Outlet />

          {/* <div className="col-sm-12 col-md-8">
            <Content content={content[subcategory]} />
            <ProductList data={products} />
          </div> */}
        </div>
      </div>
      <WhatsappContact />
      <Footer />
    </div>
  );
}

export default ProductPage;
