export const sidebarSubcategory = [
  {
    catId: 1,
    categories: "Paper Application",
    link: "enzyme-for-paper-industry",
    subcategories: "Enzyme for Paper Industry",
  },
  {
    catId: 2,
    categories: "Paper Application",
    link: "chemical-for-paper-industry",
    subcategories: "Chemical for Paper Industry",
  },
  {
    catId: 3,
    categories: "Sugar Application",
    link: "enzyme-for-sugar-industry",
    subcategories: "Enzyme for Sugar Industry",
  },
  {
    catId: 4,
    categories: "Sugar Application",
    link: "chemical-for-sugar-industry",
    subcategories: "Chemical for Sugar Industry",
  },
  {
    catId: 5,
    categories: "Other Application",
    link: "feed-additive",
    subcategories: "Feed Additive",
  },
  {
    catId: 6,
    categories: "Other Application",
    link: "waste-water-treatment",
    subcategories: "Waste Water Treatment",
  },
  {
    catId: 7,
    categories: "Other Application",
    link: "enzyme-for-laundry-and-textile",
    subcategories: "Enzyme for Laundry and Textile",
  },
  {
    catId: 8,
    categories: "Other Application",
    link: "bioethanol-application",
    subcategories: "Bioethanol Application",
  },
];
