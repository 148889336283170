import "./App.scss";
import React from "react";
import ProductPage, { ProductContent } from "./components/page/ourproduct";
import HomePage from "./components/page/home";
import ContactPage from "./components/page/contact";
import AboutUsPage from "./components/page/about";
import HalalPage from "./components/page/halal";
import RequestQuotationPage from "./components/utils/requestBuy";

import { useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { Routes, Route } from "react-router-dom";

function App() {
  const location = useLocation();

  return (
    <TransitionGroup>
      <CSSTransition timeout={300} classNames="fade" key={location.key}>
        <Routes location={location}>
          >
          <Route exact path="/" element={<HomePage />} />
          <Route exact path="/home" element={<HomePage />} />
          <Route exact path="/about" element={<AboutUsPage />} />
          <Route path="/products" element={<ProductPage />}>
            <Route path=":subcategory" element={<ProductContent />} />
          </Route>
          <Route exact path="/contact" element={<ContactPage />} />
          <Route exact path="/halal-certificate" element={<HalalPage />} />
          <Route path="/request-quotation" element={<RequestQuotationPage />} />
        </Routes>
      </CSSTransition>
    </TransitionGroup>
  );
}

export default App;
