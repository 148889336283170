import Navbar from "../header/navbar";
import Footer from "../footer/footer";
import { EMAIL } from "./email";

import React, { useState, useEffect } from "react";
import emailjs, { init } from "@emailjs/browser";
import { useForm } from "react-hook-form";

function QuotationModal(props) {
  const content = props.content;

  return (
    <div
      className="modal fade"
      id="quotationStatus"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="quotationStatusLabel"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title" id="quotationStatusLabel">
              Email Status
            </h4>
          </div>
          <div className="modal-body"> {content} </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

function QuotationForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [emailStatus, setEmailStatus] = useState(
    "There is an error occured, please fixed it first before submitting the email"
  );

  const onSubmit = (data) => {
    init(atob(EMAIL.ID_USRE));
    console.log("trying to send email");

    const parameter = {
      subject: "Request a quote",
      sender_name: data.name,
      company_name: data.company,
      email: data.email,
      message: data.message,
    };
    console.log(errors);
    if (Object.keys(errors).length === 0) {
      setEmailStatus("Please wait...");
    }

    emailjs
      .send(atob(EMAIL.ESIVRIE_DC), atob(EMAIL.ATTMPELI_IE), parameter)
      .then(
        function (response) {
          console.log("SUCCESS!", response.status, response.text);
          setEmailStatus("Your email already been sent");
        },
        function (error) {
          console.log("FAILED...", error);
          setEmailStatus(
            "There is something wrong with the server, please email us directly to marketing@saba.co.id"
          );
        }
      );
  };

  const errorMessageStyle = {
    color: "red",
    paddingLeft: "18px",
  };

  return (
    <div className="quotation">
      <h2> Thank you for contacting us. </h2>
      <p style={{ fontSize: "1.25rem", lineHeight: "2rem" }}>
        We are very pleased that you are taken interest in our product. Please
        fill the form below and we will reply to you immediately.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="quotation__forms">
        <input
          type="text"
          className="input-form"
          name="name"
          placeholder="Name"
          {...register("name", { required: true })}
        />
        {errors.name && (
          <span style={errorMessageStyle}>*Name field is required</span>
        )}
        <input
          type="email"
          className="input-form"
          name="email"
          placeholder="Email"
          {...register("email", {
            required: true,
            pattern:
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        {errors.email && (
          <span style={errorMessageStyle}>*Email field is required</span>
        )}
        <input
          type="text"
          className="input-form"
          name="company"
          placeholder="Company"
          {...register("company", { required: true })}
        />
        {errors.company && (
          <span style={errorMessageStyle}>*Company name field is required</span>
        )}
        <textarea
          className="inputarea-form"
          name="message"
          placeholder="Message"
          rows="4"
          {...register("message", { required: true })}
        />
        {errors.message && (
          <span style={errorMessageStyle}>*Message field is required</span>
        )}
        <input
          id="btn-quotation-form-submit"
          className="btn btn-default btn-border-50"
          type="submit"
          data-toggle="modal"
          data-target="#quotationStatus"
        />
      </form>
      <QuotationModal content={emailStatus} />
    </div>
  );
}

function RequestQuotationPage(props) {
  const mobileWidth = 601;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < mobileWidth;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <div className="container-fluid">
      <Navbar />
      <div className="row hero-factory hero-margin">
        <img
          src={`${
            isMobile ? "img/hero_quotation_small.png" : "img/hero_quotation.png"
          }`}
          alt="Sadya Factory"
          style={{ width: "100%" }}
        />
      </div>
      <QuotationForm />
      <Footer />
    </div>
  );
}

export default RequestQuotationPage;
