import Navbar from "../header/navbar";
import Footer from "../footer/footer";
import React, { useState, useEffect } from "react";
import { ScrollToTopOnMount } from "../scroll";
import WhatsappContact from "../utils/whatsapp";
import HexagonProcess from "../utils/hexagon";

function ProcessSection(props) {
  const ProcessBubble = (props) => {
    return <div class="process__bubble-svg"> {props.name}</div>;
  };

  return (
    <div className="process__container section-container">
      <div className="contents" style={{ paddingBottom: "2rem" }}>
        <p>
          We have several proven successful application in industrial process
          such as:
        </p>
      </div>
      <div className="process__bubbles">
        <ProcessBubble name="Pulp & Paper Mill" />
        <ProcessBubble name="Sugar Mill" />
        <ProcessBubble name="Sugar Refinery Mill" />
        <ProcessBubble name="Ceramic Tile" />
        <ProcessBubble name="Detergent" />
      </div>
    </div>
  );
}

function OurValueSection(props) {
  return (
    <div className="value">
      <div className="value__title section-container">
        <h1>Our Value </h1>
      </div>
      <div className="contents value__introduction">
        <p>
          The spirit of King Judistira was adopted as a corporate value. In the
          Hindu epic Mahabharata, Judistira is the eldest son of King Pandu and
          Queen Kunti, was king of Indraprastha and later of Hastinapura (Kuru).
          For his piety, he was known as Dharmaraja (which may be translated as
          either <i> righteous king</i> or <i>king of dharma</i>). He was the
          leader of the successful Pandava side in the Kurukshetra War or the
          Mahabharata War. At the end of the epic, he ascended to heaven along
          with his four brothers.
        </p>
      </div>
      <div className="value__contents">
        <div className="contents value__cards" id="commit">
          <h3> Commit</h3>
          <p>
            We are committed to deliver the best customer experience. We work
            with high totality to get the job done not only to achieve agreed
            quality standards but also to exceed customer's expectations.
          </p>
        </div>
        <div className="contents value__cards" id="optimistic">
          <h3> Optimistic</h3>
          <p>
            Always positive in looking at problems and not avoiding it. Our
            company always trying to solve the problems proactively and quickly
            to help customers and increase customer satisfaction.
          </p>
        </div>
        <div className="contents value__cards" id="objective">
          <h3> Objective</h3>
          <p>
            Our company act based on data and fact. We see data and facts as
            empowerment to achieve better results and tools to help us make
            decisions.
          </p>
        </div>
        <div className="contents value__cards" id="responsible">
          <h3> Responsible </h3>
          <p>
            We promote safety workplace and sustainable environment. As a
            company that engage in chemical sector, it is also our
            responsibility to keep our employees safe and our product
            eco-friendly.
          </p>
        </div>
        <div className="contents value__cards" id="innovative">
          <h3> Innovative </h3>
          <p>
            Dare to innovate and take action to embrace change. Our Engineers
            use a combination of Chemical / bio-products, analysis/monitoring
            tools, to ensure all programs are run successfully and provide the
            greatest amount of benefits to our customers.
          </p>
        </div>
      </div>
    </div>
  );
}

function OurApproachSection(props) {
  return (
    <div className="approach">
      <div className="approach__container section-container">
        <div className="approach__title ">
          <h1> Our Approach </h1>
        </div>

        <div className="rows" id="partnership">
          <div className="approach__left">
            <div className="approach__image">
              <img
                src="/img/about/about_us_meetings_1.png"
                alt="Saba Meeting 1"
              />
            </div>
          </div>
          <div className="approach__right">
            <div className="contents approach__card">
              <div>
                <h3>Innovation Of Us...</h3>
              </div>
              <p>
                We will never end displaying smart innovations for industrial
                development. We respond to customers with appropriate and clever
                applications to solve problems, improve performance and fulfill
                customer needs. Our solutions solve real challenges, offering
                real values, by helping to improve the quality of output or
                production processes.
              </p>
            </div>
          </div>
        </div>
        <div className="rows" id="relationship">
          <div className="approach__left">
            <div className="contents approach__card">
              <div>
                <h3>Staying Close To </h3>
                <h3>Our Customer...</h3>
              </div>
              <p>
                We believe there are unlimited solutions to improve industrial
                performance, thus we spend an enormous amount of staying close
                to our customers and understanding the systems to deliver the
                best possible solutions. We also believe in our superior
                qualities product, while our experienced engineer will give
                effective applications to exceed customer expectations.
              </p>
            </div>
          </div>
          <div className="approach__right">
            <div className="approach__image">
              <img
                src="/img/about/about_us_meetings_2.png"
                alt="Saba Meeting 1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function IntroductionSection(props) {
  return (
    <section className="intro section-container">
      <div className="intro__container">
        <div className="intro__left">
          <img src="/img/about/about_us_building_2.png" alt="Saba Meeting 1" />
        </div>
        <div className="intro__right">
          <div className="contents">
            <p>
              <span className="bold"> PT. Sadya Balawan (SaBa) </span> was
              established on September 06, 2009 as an innovator of Specialty
              Chemical and Enzyme / Bio-product for Industries. We already
              engaged in industrial Pulp And Paper Application, Waste Water
              Treatment, Sugar Processing Application, Bioethanol Application,
              Feed Additive Enzyme, Laundry And Textile Application.
            </p>
            <p>
              The company dedicated to industrial needs by delivering innovative
              solutions with superior quality of program applications and
              products which will increase customer process performance with
              competitive economic advantage.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function AboutUsPage(props) {
  const mobileWidth = 601;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobileWidth);
  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < mobileWidth;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false
    );
  }, [isMobile]);

  return (
    <div className="container-fluid">
      <ScrollToTopOnMount />
      <Navbar currentPage="about" />
      <div className="row hero-factory hero-margin">
        <img
          src={`${
            isMobile
              ? "img/home/hero_paper_mill_small.png"
              : "img/about/about_us_hero_factory.png"
          }`}
          alt="Night Factory"
          style={{ width: "100%", minHeight: "50%" }}
        />
        {/* <div className="hero-factory__p">
          <p>
            We are a leading company that provides a high quality manufactures
            of Chemical and Enzyme Bio - Product.{" "}
          </p>
        </div> */}
      </div>

      <IntroductionSection />
      <hr className="section-divider" />
      {/* <ProcessSection /> */}
      <HexagonProcess />
      <OurValueSection />
      <OurApproachSection />
      <WhatsappContact />
      <Footer />
    </div>
  );
}

export default AboutUsPage;
