import { Link } from "react-router-dom";
import React, { useState } from "react";

function Navbar(props) {
  const [isActive, setActive] = useState(false);
  const currentPage = props.currentPage || "";
  const toggleHamburger = () => {
    setActive(!isActive);
  };

  return (
    <header className={`header ${isActive ? "open" : ""}`}>
      <div
        className={`overlay has-fade ${isActive ? "fade-in" : "fade-out"}`}
      ></div>
      <nav className="flex flex-jc-sb flex-ai-c">
        <a href="/" className="header__logo">
          <img src="/logo/logo.png" alt="PT. Sadya Balawan" />
        </a>
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={toggleHamburger}
          id="btnHamburger"
          className={`header__toggle hide-for-desktop`}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div className="header__links font__600 hide-for-mobile pad-r-20">
          <Link to="/home" className={currentPage === "home" && "active"}>
            Home
          </Link>
          <Link to="/about" className={currentPage === "about" && "active"}>
            About
          </Link>
          <Link
            to="/products/enzyme-for-paper-industry"
            className={currentPage === "products" && "active"}
          >
            Our Products
          </Link>
          <Link
            to="/halal-certificate"
            className={currentPage === "halal" && "active"}
          >
            Halal Certificate
          </Link>
          <Link to="/contact" className={currentPage === "contact" && "active"}>
            Contact Us
          </Link>
        </div>
      </nav>
      <div
        className={`header__menu font__600 has-fade hide-for-desktop ${
          isActive ? "fade-in" : "fade-out"
        }`}
      >
        <Link to="/home">Home</Link>
        <Link to="/about">About</Link>
        <Link to="/products/enzyme-for-paper-industry">Our Products</Link>
        <Link to="/halal-certificate">Halal Certificate</Link>
        <Link to="/contact">Contact Us</Link>
      </div>
    </header>
  );
}

export default Navbar;
